export class LancamentoFaturas {
  constructor({
    id,
    fornecedor_id,
    data_referencia,
    data_lancamento,
    observacao,
    total_faturado,
    lancamentos,
  } = {
      id: '',
      fornecedor_id: '',
      data_referencia: (new Date()).toISOString().slice(0, 10),
      data_lancamento: (new Date()).toISOString().slice(0, 10),
      observacao: '',
      total_faturado: 0,
      lancamentos: [],
    }) {
    this.id = id
    this.fornecedor_id = fornecedor_id
    this.data_referencia = data_referencia
    this.data_lancamento = data_lancamento
    this.observacao = observacao
    this.total_faturado = total_faturado
    this.lancamentos = lancamentos
  }
}

export function createLancamentoFaturas (data) {
  return Object.freeze(new LancamentoFaturas(data))
}
