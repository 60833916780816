<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-else>
          <crud-list
            v-model="lancamentos"
            :permission="'list_lancamento_faturamento'"
            :headers="headers"
            :actions="actions"
            :sort-desc="[false]"
            :slots="['item.data_referencia', 'item.data_lancamento', 'item.valor_relatorio', 'item.valor_devolucao', 'item.valor_xml', 'item.valor_diferenca', 'item.valor_liquido']"
            show-expand
            total-column
            :export-pdf-xlsx="true"
          >
            <template v-slot:[`item.data_referencia`]="{ item }">
              <span>{{ moment( item.data_referencia ).format( "MM/YYYY") }}</span>
            </template>
            <template v-slot:[`item.data_lancamento`]="{ item }">
              <span>{{ moment( item.data_lancamento ).format( "DD/MM/YYYY") }}</span>
            </template>
            <template v-slot:[`item.valor_relatorio`]="{ item }">
              <span>R$ {{ currencyFormatter(item.valor_relatorio || 0) }}</span>
            </template>
            <template v-slot:[`item.valor_devolucao`]="{ item }">
              <span>R$ {{ currencyFormatter(item.valor_devolucao || 0) }}</span>
            </template>
            <template v-slot:[`item.valor_liquido`]="{ item }">
              <span>R$ {{ currencyFormatter(item.valor_liquido || 0) }}</span>
            </template>
            <template v-slot:[`item.valor_xml`]="{ item }">
              <span>R$ {{ currencyFormatter(item.valor_xml || 0) }}</span>
            </template>
            <template v-slot:[`item.valor_diferenca`]="{ item }">
              <span>R$ {{ currencyFormatter(item.valor_diferenca || 0) }}</span>
            </template>
            <template slot="datas">
              <v-col

                cols="12"
                sm="6"
                md="6"
              >
                <data-field
                  v-model="data_inicial"
                  :label="'Data Inicial'"
                  :data-max="data_final"
                  @set="handleDataFilter()"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <data-field
                  v-model="data_final"
                  :label="'Data Final'"
                  :data-min="data_inicial"
                  @set="handleDataFilter()"
                />
              </v-col>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td
                v-if="item.lancamentos.length"
                :colspan="headers.length"
              >
                <v-simple-table
                  :height="$vuetify.breakpoint.xl ? '300' : '200'"
                  fixed-header
                  class="my-1"
                >
                  <thead>
                    <tr>
                      <th class="text-left">
                        Código
                      </th>
                      <th class="text-left">
                        Associado
                      </th>
                      <th>
                        Observação
                      </th>
                      <th class="text-right">
                        Valor Relatório
                      </th>
                      <th class="text-right">
                        Valor Devolução
                      </th>
                      <th class="text-right">
                        Valor XML
                      </th>
                      <th
                        class="text-right"
                      >
                        Valor Diferença
                      </th>
                      <th
                        class="text-left"
                      >
                        Evento para Cálculo Manual
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="( assoc, index ) in item.lancamentos"
                      :key="index"
                    >
                      <td
                        class="text-right"
                        width="20"
                      >
                        {{ assoc.associado.codigo }}
                      </td>
                      <td
                        class="text-left"
                        width="800"
                      >
                        {{ assoc.associado.razao_social }}
                      </td>
                      <td>
                        {{ assoc.observacao }}
                      </td>
                      <td
                        width="50"
                        class="text-right"
                      >
                        R$ {{ currencyFormatter( assoc.valor_relatorio ) }}
                      </td>
                      <td
                        width="50"
                        class="text-right"
                      >
                        R$ {{ currencyFormatter( assoc.valor_devolucao ) }}
                      </td>
                      <td
                        width="50"
                        class="text-right"
                      >
                        R$ {{ currencyFormatter( assoc.valor_xml ) }}
                      </td>
                      <td
                        width="50"
                        class="text-right"
                      >
                        R$ {{ currencyFormatter( assoc.valor_diferenca ) }}
                      </td>
                      <td
                        width="50"
                        class="text-left"
                      >
                        <span v-if="assoc?.evento?.codigo !== undefined">
                          {{ assoc?.evento?.codigo + ' - ' + assoc?.evento?.descricao }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </template>
            <template
              v-if="hasPermission('inserir_lancamento_faturamento')"
              v-slot:btnCadastro
            >
              <v-col>
                <v-btn
                  rounded
                  block
                  class="text-none text-white"
                  color="primary"
                  title="Fazer novo Lançamento"
                  @click="NEW_LANCAMENTO"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Lançar
                </v-btn>
              </v-col>
              <lancamento-dialog />
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import LancamentoFaturasStore, { BOOTSTRAP, DELETE_LANCAMENTO_FATURAMENTO, EDIT_LANCAMENTO, NEW_LANCAMENTO, PERIODO } from '@/store/modules/lancamentoFaturas'
  import LancamentoFaturaStore, { CLOSE } from '@/store/modules/forms/lancamentoFatura'
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
  import { sortDateTime } from '../../utils/sorts'
  import LancamentoDialog from './LancamentoDialog.vue'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    components: {
      LancamentoDialog,
    },
    data () {
      return {
        currencyFormatter,
        data_inicial: 'from',
        data_final: 'to',
        actions: [
          {
            title: 'Editar Lançamento',
            color: 'info darken-3',
            click: item => this.EDIT_LANCAMENTO(item),
            icon: 'mdi-pencil',
            permission: 'editar_lancamento_faturamento',
          },
          {
            title: 'Excluir Lançamento',
            color: 'red darken-3',
            click: item => this.DELETE_LANCAMENTO_FATURAMENTO(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_lancamento_faturamento',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, floatingfilter: false, width: '20' },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Referência', value: 'data_referencia', width: 'auto', sort: sortDateTime, report: true, styleReport: { dateReference: 'true', titulo: 'Ref.' } },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Lançamento', value: 'data_lancamento', width: 'auto', sort: sortDateTime, report: true },
          { align: 'right', sortable: true, groupable: false, floatingfilter: true, text: 'Código', value: 'fornecedor.codigo', width: '30', report: true, styleReport: { halign: 'right', titulo: 'Cód.' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Fornecedor', value: 'fornecedor.razao_social', width: 'auto', report: true, styleReport: { cellWidth: 70 } },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor do Faturado', value: 'valor_relatorio', width: 'auto', sum: 'Money', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor de Devolução', value: 'valor_devolucao', width: 'auto', sum: 'Money', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor Líquido', value: 'valor_liquido', width: 'auto', sum: 'Money', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor do XML', value: 'valor_xml', width: 'auto', sum: 'Money', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor de Diferença', value: 'valor_diferenca', width: 'auto', sum: 'Money', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
        ],
      }
    },
    computed: {
      ...mapState('lancamentoFaturas', ['loading', 'lancamentos']),
      ...mapState('form/lancamentoFatura', ['dialog']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.state.form.lancamentoFatura) { this.$store.registerModule(['form', 'lancamentoFatura'], LancamentoFaturaStore) }
      if (!this.$store.state.lancamentoFaturas) { this.$store.registerModule('lancamentoFaturas', LancamentoFaturasStore) }

      let data_i = new Date()

      if (data_i.getMonth() === 0) {
        data_i = new Date(data_i.getFullYear() - 1, 12, 1)
      } else {
        data_i = new Date(data_i.getFullYear(), data_i.getMonth() - 1, 1)
      }

      let data_f = new Date(data_i.getFullYear(), data_i.getMonth() + 2, 0)
      data_i = data_i.toISOString().slice(0, 10)
      data_f = data_f.toISOString().slice(0, 10)

      this.data_inicial = data_i
      this.data_final = data_f

      this.PERIODO({ data_i, data_f })

      this.BOOTSTRAP()

      this.$store.watch(('dialog', (val) => {
        val || this.CLOSE()
      }))
    },
    methods: {
      ...mapActions('lancamentoFaturas', [BOOTSTRAP, DELETE_LANCAMENTO_FATURAMENTO, NEW_LANCAMENTO, EDIT_LANCAMENTO]),
      ...mapMutations('form/lancamentoFatura', [CLOSE]),
      ...mapMutations('lancamentoFaturas', [PERIODO]),
      handleDataFilter () {
        const data_i = this.data_inicial
        const data_f = this.data_final
        this.PERIODO({ data_i, data_f })
        this.BOOTSTRAP()
      },
    },
  }
</script>
